<template>
  <section>
    <content-wrapper>
      <loader v-if="this.loading"></loader>
      <post
        v-if="post"
        :post="post"
        :key="post.id"
      />
    </content-wrapper>
  </section>
</template>

<script>
import Post from '../components/Blog/Post';
import ContentWrapper from '../components/Layout/ContentWrapper';
import Loader from '../components/Layout/Loader';
import { showNotification } from '../data/services';
export default {
  name: 'Blog',
  components: {
    Post,
    ContentWrapper,
    Loader,
  },
  methods: {
    async getContent() {
      this.loading = true;
      try {
        const response = await this.$prismic.client.query([
          this.$prismic.Predicates.at('document.id', this.$route.params.postId),
        ]);
        const { results } = response;
        if (results[0]) {
          this.post = results[0];
        } else {
          this.$router.push({ name: 'not-found-page' });
        }
      } catch {
        showNotification('Something went wrong', 'error');
      } finally {
        this.loading = false;
      }
    }
  },
  created() {
    this.getContent();
  },
  data() {
    return {
      post: null,
      loading: false,
    }
  }
}
</script>

<style lang="scss" scoped>
  @media (max-width: $table-screen) {
    .custom-loader {
      margin-top: 100px;
    }
}
</style>