<template>
  <div :class="['post', { 'full-post' : !isPreview }]">
    <router-link
      class="title"
      :to="`/blog/${post.id}`"
      v-if="isPreview"
    >
      <prismic-rich-text :field="post.data.title"/>
    </router-link>
    <div v-else class="title">
      <prismic-rich-text :field="post.data.title"/>
    </div>
    <div class="meta">
      <div class="date">{{this.convertDate()}}</div>
      <div class="tags" v-if="post.tags.length">
        <div v-for="tag in post.tags" :key="tag" class="tag">{{tag}}</div>
      </div>
    </div>
    <prismic-rich-text
      :field="isPreview ? post.data.preview : post.data.post"
      :htmlSerializer="htmlSerializer"
      class="text"
    />
    <router-link
      v-if="isPreview"
      class="show-more"
      :to="`/blog/${post.id}`"
    >
      Читать полностью
    </router-link>
    <Button v-if="!isPreview" @on-click="handleClick">
      К списку постов
    </Button>
  </div>
</template>

<script>
  import { monthsList } from '../../data/constants';
  import Button from '../Button';
  export default {
    name: 'Blog',
    components: {
      Button
    },
    props: ['post', 'isPreview'],
    methods: {
      convertDate() {
        if (!this.post.data?.date) {
          return '';
        }
        const date = new Date(this.post.data.date);
        return `${date.getDate()} ${monthsList[date.getMonth()]} ${date.getFullYear()}`;
      },
      htmlSerializer(type, element, _, children) {
        if (type === 'hyperlink') {
          return `<a target="_blank" href="${element.data.url}">${children.join("")}</a>`;
        }
        return null;
      },
      handleClick() {
        this.$router.push('/blog');
      }
    }
  }
</script>

<style lang="scss" scoped>
  h2 {
    font-weight: bold;
  }

  .title {
    color: black;
  }

  .meta {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .tags {
    display: flex;
    flex-wrap: wrap;
  }

  .date {
    color: $light-gray;
    margin-right: 30px;
  }

  .tag {
    padding: 4px 12px;
    margin: 0 5px 10px;
    background: #EFEFEF;
    border-radius: 4px;
    color: $gray;
    font-size: 14px;
  }

  .text {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.05em;
    color: #3e3d3d;
  }

  .show-more {
    color: $black;
    margin-top: -10px;
    cursor: pointer;
    font-size: 15px;
    text-decoration: underline !important;
  }

  button {
    text-align: center;
    margin: 40px auto 0;
  }

  @media (max-width: $table-screen) {
    .post {
      .meta {
        display: block;
      }

      &.full-post {
        .meta {
          margin-top: 50px;
        }

        .text {
          line-height: 32px;
          font-size: 18px;
        }

        .title {
          font-size: 18px;
          text-align: center;
        }
      }
    }

    .date {
      margin-bottom: 15px;
    }

    .tag:first-child {
      margin-left: 0;
    }
  }

</style>

<style lang="scss">
  p.block-img {
    text-align: center;
    margin: 25px 0;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .text pre {
    white-space: pre-wrap;
    background-color: #f1f1f1;
    color: $black;
    padding: 10px;
    border-radius: 3px;
    font-size: 14px;
  }

  [data-oembed-provider="YouTube"] iframe {
    width: 100%; height: 500px;
  }

  .post .title >* {
    display: inline-block;
  }
</style>
