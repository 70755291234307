<template>
  <div class="content-wrapper">
    <section>
      <slot />
    </section>
  </div>
</template>

<script>
  export default {
    name: 'ContentWrapper',
  }
</script>

<style lang="scss" scoped>
  .content-wrapper {
    width: 900px;
    margin: 0 auto;
    padding: 80px 0;

    @media (max-width: 1140px) {
      width: 100%;
      padding: 80px 120px;
    }

    @media (max-width: $laptop-screen) {
      padding: 80px 60px;
    }

    @media (max-width: $table-screen) {
      padding: 30px 60px;
    }

    @media (max-width: $mobile-screen) {
      padding: 30px;
    }
  }
</style>